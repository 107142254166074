<template>
  <b-overlay
    :show="blocking"
    rounded="sm"
  >
    <section class="app-ecommerce-details">

      <!-- Alert: No item found -->
      <b-alert
        variant="danger"
        :show="product === undefined"
      >
        <h4 class="alert-heading">
          Error obteniendo la información del producto
        </h4>
        <div class="alert-body">
          No se ha encontrado el producto con ese código, ve a la
          <b-link
            class="alert-link"
            :to="{ name: 'apps-e-commerce-shop'}"
          >
            tienda
          </b-link>
          y explora otros.
        </div>
      </b-alert>

      <!-- Content -->
      <b-card
        v-if="product"
        no-body
      >
        <b-card-body>
          <b-row class="my-2">

            <!-- Left: Product Image Container -->
            <b-col
              cols="12"
              md="5"
              class="d-flex align-items-center justify-content-center mb-2 mb-md-0"
            >
              <b-container>
                <b-row>
                  <b-col
                    cols="12"
                    md="2"
                    :order="isDesktop ? 1 : 2"
                    :class="{'height-mobile': !isDesktop}"
                  >
                    <swiper
                      ref="productSlideThumbs"
                      class="swiper gallery-thumbs"
                      style="height: 30vh"
                      :options="swiperOptionThumbs"
                    >
                      <swiper-slide
                        v-for="image in product.thumbnails"
                        :key="image"
                      >
                        <b-img
                          :src="image"
                          :alt="`${product.name}-${product.id}`"
                          fluid
                        />
                      </swiper-slide>
                    </swiper>
                  </b-col>
                  <b-col
                    cols="12"
                    md="10"
                    :order="isDesktop ? 2 : 1"
                  >
                    <swiper
                      ref="productSlideShow"
                      class="swiper-gallery gallery-top"
                      :options="swiperOption"
                    >
                      <swiper-slide
                        v-for="(image, index) in product.thumbnails"
                        :id="product.media[index].id"
                        :key="product.media[index].id"
                        :virtual-index="product.media[index].id"
                        class="text-center"
                      >
                        <b-img
                          :src="image"
                          :alt="`${product.name}-${product.id}`"
                          class="mx-auto"
                          fluid9-
                        />
                      </swiper-slide>
                      <div
                        slot="button-next"
                        class="swiper-button-next swiper-button-white"
                      />
                      <div
                        slot="button-prev"
                        class="swiper-button-prev swiper-button-white"
                      />
                    </swiper>
                  </b-col>
                </b-row>

              </b-container>
            </b-col>

            <!-- Right: Product Details -->
            <b-col
              cols="12"
              md="7"
            >

              <!-- Product Name -->
              <h4>{{ product.name }}</h4>

              <!-- Price And Ratings -->
              <div class="ecommerce-details-price d-flex flex-wrap mt-1">
                <h2 class="item-price mr-1">
                  ${{ product.price }}
                </h2>
              </div>

              <!-- Avability -->
              <b-card-text v-if="product.stock > 0">
                Disponible - <span class="text-success">En inventario</span>
              </b-card-text>
              <b-card-text v-else>
                No disponible - <span class="text-danger">Sin inventario</span>
              </b-card-text>

              <!-- Product Description -->
              <b-card-text>{{ product.description }}</b-card-text>

              <!-- Product Meta [Free shpping, EMI, etc.] -->
              <ul class="product-features list-unstyled">
                <li v-if="product.qty_free_shipping === 1">
                  <feather-icon icon="ShoppingCartIcon" />
                  <span>Envío gratis</span></li>
                <li v-else>
                  <feather-icon icon="DollarSignIcon" />
                  <span>Envío gratis a partir de {{ product.qty_free_shipping }} unidades</span>
                </li>
              </ul>

              <b-form-group
                v-if="brands.length"
                label="Marca"
              >
                <b-form-radio-group
                  id="brand-selector"
                  v-model="brand"
                  button-variant="outline-primary"
                  :options="brands"
                  buttons
                  name="brand"
                  value-field="id"
                  text-field="value"
                  @change="changeBrand"
                />
              </b-form-group>

              <b-form-group
                v-if="genders.length"
                label="Género"
              >
                <b-form-radio-group
                  id="size-selector"
                  v-model="gender"
                  button-variant="outline-primary"
                  :options="genders"
                  buttons
                  name="gender"
                  value-field="id"
                  text-field="value"
                />
              </b-form-group>

              <b-form-group
                v-if="colors.length"
                label="Color"
              >
                <b-form-radio-group
                  id="color-selector"
                  v-model="color"
                  button-variant="outline-primary"
                  :options="colors"
                  buttons
                  name="color"
                  value-field="id"
                  text-field="value"
                  @change="changeColor"
                />
              </b-form-group>

              <b-form-group
                v-if="sizes.length"
                label="Talla"
              >
                <b-form-radio-group
                  id="size-selector"
                  v-model="size"
                  button-variant="outline-primary"
                  :options="sizes"
                  buttons
                  name="size"
                  value-field="id"
                  text-field="value"
                />
              </b-form-group>

              <div class="d-flex flex-column flex-sm-row pt-1">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  :variant="product.isInCart ? 'success' : 'primary'"
                  class="btn-cart mr-0 mr-sm-1 mb-1 mb-sm-0"
                  :disabled="blocking"
                  @click="handleCartActionClick(product, {brand, color, size, gender})"
                >
                  <feather-icon
                    icon="ShoppingCartIcon"
                    class="mr-50"
                  />
                  <span>{{ product.isInCart ? 'Ver en el carrito' : 'Agregar al carrito' }}</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </b-card-body>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import store from '@/store'
import { ref } from '@vue/composition-api'
import {
  BCard, BCardBody, BRow, BCol, BImg, BCardText, BLink, BButton, BAlert, BFormGroup, BFormRadioGroup, BContainer, BOverlay,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { useEcommerceUi } from '../useEcommerce'
import 'swiper/css/swiper.css'

export default {
  directives: {
    Ripple,
  },
  components: {
    // BSV
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BAlert,
    BFormGroup,
    BFormRadioGroup,
    BContainer,
    BOverlay,
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      swiperOption: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: window.innerWidth > 768 ? 50 : 20,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      },
      product: null,
      brand: null,
      color: null,
      size: null,
      gender: null,
      brands: [],
      colors: [],
      sizes: [],
      genders: [],
      swiperOptionThumbs: {
        loop: true,
        loopedSlides: 5,
        spaceBetween: window.innerWidth > 768 ? 50 : 25,
        centeredSlides: true,
        slidesPerView: 4,
        touchRatio: 0.2,
        slideToClickedSlide: true,
        direction: window.innerWidth > 768 ? 'vertical' : 'horizontal',
      },
      blocking: true,
    }
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 768
    },
  },
  mounted() {
    const productSlug = this.$route.params.slug
    console.log(productSlug)

    store.dispatch('products/show', { productSlug })
      .then(async response => {
        console.log(response.data.products[0])
        // eslint-disable-next-line prefer-destructuring
        this.product = response.data.products[0]

        await new Promise(r => setTimeout(r, 1000))

        console.log(this.$refs.productSlideShow)
        const swiperTop = this.$refs.productSlideShow.$swiper
        const swiperThumbs = this.$refs.productSlideThumbs.$swiper
        swiperTop.controller.control = swiperThumbs
        swiperThumbs.controller.control = swiperTop

        this.brands = this.product.variants.filter(variant => variant.key === 'brand')
        if (this.brands.length) {
          this.brand = this.brands[0].id
        }
        this.colors = this.product.variants.filter(variant => variant.key === 'color')
        if (this.colors.length) {
          this.color = this.colors[0].id
        }
        this.sizes = this.product.variants.filter(variant => variant.key === 'size')
        if (this.sizes.length) {
          this.size = this.sizes[0].id
        }
        this.genders = this.product.variants.filter(variant => variant.key === 'gender')
        if (this.genders.length) {
          this.gender = this.genders[0].id
        }
        this.blocking = false

        this.changeColor()
        this.changeBrand()
      })
      .catch(error => {
        console.log(error)
        if (error.response && error.response.status === 404) {
          this.product = undefined
        } else {
          this.changeColor()
          this.changeBrand()
        }
      })
  },
  methods: {
    changeBrand() {
      const brandId = this.brand
      const colorId = this.color
      const mediaFind = this.product.media.filter(media => {
        if (this.colors && media.custom_properties.color) {
          return media.custom_properties.brand === brandId && media.custom_properties.color === colorId
        }
        return media.custom_properties.brand === brandId
      })
      // eslint-disable-next-line consistent-return
      this.$refs.productSlideShow.$swiper.slides.each((index, slide) => {
        // eslint-disable-next-line eqeqeq
        if (slide.getAttribute('id') == mediaFind[0].id) {
          this.$refs.productSlideShow.$swiper.slideTo(index)
          return false
        }
      })
    },
    changeColor() {
      const colorId = this.color
      const brandId = this.brand
      const mediaFind = this.product.media.filter(media => {
        if (this.brands && media.custom_properties.brand) {
          return media.custom_properties.brand === brandId && media.custom_properties.color === colorId
        }
        return media.custom_properties.color === colorId
      })
      // eslint-disable-next-line consistent-return
      this.$refs.productSlideShow.$swiper.slides.each((index, slide) => {
        // eslint-disable-next-line eqeqeq
        if (slide.getAttribute('id') == mediaFind[0].id) {
          this.$refs.productSlideShow.$swiper.slideTo(index)
          return false
        }
      })
    },
  },
  setup() {
    const { handleCartActionClick, toggleProductInWishlist } = useEcommerceUi()

    // UI
    const selectedColor = ref(null)

    return {
      // UI
      selectedColor,
      handleCartActionClick,
      toggleProductInWishlist,
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce-details.scss";

.break {
  flex-basis: 100%;
  height: 0;
}
.height-mobile {
  height: 100px;
}

.gallery-thumbs .swiper-slide {
  opacity: 0.4;
}

.gallery-thumbs .swiper-slide-active {
  opacity: 1;
}
</style>
